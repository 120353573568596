<template>
  <div class="list__item-wrapper">
    <div class="list__item__header">
      <span class="list__item__header__id"> ID: {{ listIndex }} </span>
      <div class="list__item__header__controls">
        <div
          class="list__item__header__controls__move-up"
          v-if="listIndex !== 0 && !disabled"
          @click="hooks.moveUp(listIndex)"
        >
          <font-awesome-icon icon="caret-up" />
        </div>
        <div
          class="list__item__header__controls__move-down"
          v-if="
            listTotalCount > 0 && listIndex !== listTotalCount - 1 && !disabled
          "
          @click="hooks.moveDown(listIndex)"
        >
          <font-awesome-icon icon="caret-down" />
        </div>
        <div
          class="list__item__header__controls__trash"
          v-if="!disabled"
          @click="hooks.remove(listIndex)"
        >
          <font-awesome-icon icon="trash" />
        </div>
      </div>
      <hr />
    </div>
    <div
      class="list__input"
      v-for="(key, index) in keys"
      :key="index"
      :set="(value = values[index])"
    >
      <CollapsibleContentLight
        :wrap="ifWrapIntoCollapsible(value)"
        :title="value.label"
        :content-padding="collapsiblePadding(value)"
        :button-bg-color="`${colorsHtml.background}`"
        :button-padding="'7px 15px'"
      >
        <div v-if="value.datatype === 'text'">
          <FormulateInput
            type="text"
            :name="key"
            :label="value.label"
            :placeholder="value.label"
            error-behavior="live"
            :value="value.default"
            :disabled="disabled"
            v-bind:class="editableClass"
            v-on:change="edit($event.target.value, key)"
          />
        </div>
        <div v-if="value.datatype === 'checkbox'">
          <FormulateInput
            type="checkbox"
            :name="key"
            :value="value.default"
            :checked="value.default"
            :label="value.label"
            :disabled="disabled"
            v-on:change="edit($event.target.checked, key)"
          />
        </div>
        <div v-if="value.datatype === 'date'">
          <FormulateInput
            type="date"
            :name="key"
            :value="value.default"
            :checked="value.default"
            :label="value.label"
            :disabled="disabled"
            v-on:change="edit($event.target.value, key)"
          />
        </div>
        <div v-if="value.datatype === 'select'">
          <FormulateInput
            :options="sampleObject[key].options"
            type="select"
            :name="key"
            :disabled="disabled"
            :value="value.default"
            :label="value.label"
            v-bind:class="editableClass"
            v-on:change="edit($event.target.value, key)"
            :placeholder="value.label"
          />
        </div>
        <div v-if="value.datatype === 'number'">
          <FormulateInput
            type="number"
            :name="key"
            :label="value.label"
            :value="value.default"
            :placeholder="value.label"
            :disabled="disabled"
            validation="number"
            v-bind:class="editableClass"
            v-on:change="edit($event.target.value, key)"
            error-behavior="live"
          />
        </div>

        <div v-if="value.datatype === 'file'">
          <FileInput
            :name="key"
            :url="fileUrl(value.default)"
            :label="value.label"
            :value="value.default"
            :editable="!disabled"
            v-on:file-changed="edit($event, key)"
          />
        </div>

        <div v-if="value.datatype === 'image'">
          <ImageInput
            :name="key"
            :label="value.label"
            :url="fileUrl(value.default)"
            :value="value.default"
            :editable="!disabled"
            v-on:image-changed="edit($event, key)"
          />
        </div>

        <div v-if="value.datatype === 'richtext'">
          <RichTextEditor
            :bodyTitle="value.label"
            :editable="!disabled"
            :value="value.default"
            v-bind:class="editableClass"
            v-on:change-input="edit($event, key)"
          />
        </div>

        <div v-if="value.datatype === 'list' && value.object">
          <List
            :editable="!disabled"
            v-bind:value="value.default"
            v-bind:label="value.label"
            v-bind:object="value.object"
            v-on:list-changed="edit($event, key)"
          />
        </div>

        <div v-if="value.datatype === 'color'">
          <FormulateInput
            type="color"
            :name="key"
            v-bind:value="value.default"
            v-bind:label="value.label"
            v-on:input="edit($event, key)"
            :disabled="disabled"
          />
        </div>
      </CollapsibleContentLight>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faPen,
  faTrash,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

import colorsHtml from "/colors.config.json";
library.add(faPen, faTrash, faCaretUp, faCaretDown);

export default {
  name: "ListItem",
  components: {
    CollapsibleContentLight: () =>
      import("@/components/Collapsible/CollapsibleContentLight"),
    ImageInput: () => import("../Input/ImageInput.vue"),
    FileInput: () => import("../Input/FileInput.vue"),
    RichTextEditor: () => import("@/components/TextEditor/RichTextEditor"),
    List: () => import("@/components/List/List"),
  },
  props: {
    dataObject: {
      type: Object,
      default: () => {},
    },
    listIndex: {
      type: Number,
      default: 0,
    },
    listTotalCount: {
      type: Number,
      default: 0,
    },
    sampleObject: {
      type: Object,
      default: () => {},
    },
    hooks: {
      type: Object,
      default: () => {},
    },
    isSettings: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorsHtml: colorsHtml,
    };
  },
  computed: {
    editableClass() {
      if (this.disabled) {
        return "not-editable";
      }
      return "";
    },
    keys() {
      return Object.keys(this.dataObject);
    },
    values() {
      let vals = JSON.parse(JSON.stringify(this.dataObject));

      for (let prop in vals) {
        if (typeof this.dataObject[prop].value !== "undefined") {
          vals[prop].default = this.dataObject[prop].value;
        }

        if (
          typeof vals[prop].default === "undefined" &&
          typeof this.dataObject[prop].value === "undefined"
        ) {
          vals[prop].default = "";
        }

        // assign default values to state so they will be saved in the editor
        if (this.sampleObject[prop].default && !this.dataObject[prop].value) {
          this.edit(this.sampleObject[prop].default, prop);
        }
      }

      return Object.values(vals);
    },
  },
  methods: {
    edit(v, key) {
      this.$emit("list-edit", this.listIndex, v, key);
    },
    fileUrl(file) {
      if (typeof file === "object" && file.name) {
        return URL.createObjectURL(new Blob([file], { type: file.type }));
      }
      if (typeof file === "string" && file !== "") {
        return window.location.origin + file;
      }

      return "";
    },
    ifWrapIntoCollapsible(value) {
      return (
        (typeof value.optional !== "undefined" && value.optional === true) ||
        value.datatype === "list"
      );
    },
    collapsiblePadding(value) {
      if (value.datatype === "list" && !this.isSettings) {
        return "15px 0px 0px 0px";
      } else if (this.isSettings) {
        return "0px";
      }
      return "15px";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.list__item {
  .not-editable input,
  .not-editable .editor,
  .not-editable .editor__content,
  .not-editable select {
    border: none !important;
    padding: 0px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__id {
      font-size: 14px;
      color: $dark;
    }

    &__controls {
      display: flex;
      justify-content: space-between;

      div {
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
        transition: 0.3s;

        svg {
          color: $light_text;
        }
      }
      div:hover {
        opacity: 0.7;
      }
      div:last-child {
        margin-right: 0px;
      }
    }

    hr {
      width: 100%;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
.list__input {
  margin-bottom: 15px;
}
</style>
